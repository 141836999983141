import React from 'react';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const Layout = loadable(() => import('../components/Layout'));
const Head = loadable(() => import('../components/Head'));
const EmpoweringWomen = loadable(() => import('../components/donate/donate-main'));

const Donate = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Empowering Women" />
        <EmpoweringWomen />
      </Layout>
    </ThemeProvider>
  );
};

export default Donate;
